<template>
  <div>

    <section class="section1 ">
      <div class="seccionlore1 backazul container-fluid">
        <div class="row">
          <div class="col-9 pe-0">
            <p style="letter-spacing: -0.01em" class="titulo1 textoazul tituloGrandeCentrado">
              Sabemos que un buen diseño funciona cuando deja huella en la sociedad
            </p>
            <p class="texto2 textoazul">
              Un logoemblema, Imagotipo, logotipo, isotipo, marca, ícono o
              monograma, tienen la función de identificar el producto o servicio de
              una empresa de manera visual. Estos símbolos gráficos permiten que las
              empresas se reconozcan y se diferencien unas de otras.
            </p>
          </div>
          <div class="col-3 ps-0">
            <img src="../assets/imagenes/inicio/huella.png" alt="huella" class="huella ">
          </div>
        </div>
      </div>
    </section>


    <!----------Fila 1-------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/acapulco_citrus.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Jugos de Fruta <br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/aga_ace_iberica.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Grupo AGA <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img src="../assets/imagenes/ig/st.png" alt="" class="acapulco"/>
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Bufet de Abogados <br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 2-------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/certificado_digital.png"
                alt=""
                class="cert"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Grupo AGA <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/conceptos_graficos.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Agencia de Diseño Gráfico <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/chile_y_toro.png"
                alt=""
                class="chile"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Bar de Tapas y Botanas <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 3-------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/conferencia_mexico.png"
                alt=""
                class="confe"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Instituto de México en España<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/cuatro_puntos.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Café gourmet <br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/educacion_por_el_arte.png"
                alt=""
                class="edu"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Asociación de Arte <br/>
              <b>Lugar:</b> Monterrey, México
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 4-------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/desfile_navideno.png"
                alt=""
                class="navi"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Plaza Fiesta San Agustín<br/>
              <b>Lugar:</b> Monterrey, México
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/dragon_de_oro.png"
                alt=""
                class="dragon"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Restaurante de Mariscos<br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/fisionaciones.png"
                alt=""
                class="fisio"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Clínica de Fisioterapia <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 5-------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/emprendedores_madrid.png"
                alt=""
                class="navi"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Grupo AGA<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/feria_del_libro.png"
                alt=""
                class="feria"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Universidad Loyola del Pacífico<br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/huellitas.png"
                alt=""
                class="huellita"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Productos para Mascotas <br/>
              <b>Lugar:</b> Acapulco, México
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 6-------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/fumitec.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Empresa de Fumigaciones<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img src="../assets/imagenes/ig/hb.png" alt="" class="dragon"/>
            <p class="texto4 textogris">
              <b>Cliente:</b> Empresa de Programación web<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/notificaciones.png"
                alt=""
                class="noti"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Grupo AGA <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 7-------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/mi_sazon.png"
                alt=""
                class="acapulco"
            />
            <p class="texto4 textogris">
              <b>Cliente:</b> Talleres de Cocina<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img src="../assets/imagenes/ig/mesa_reservada.png" alt="" class="dragon"/>
            <p class="texto4 textogris">
              <b>Cliente:</b> Blog Culinario<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
            <img
                src="../assets/imagenes/ig/quimichis.png"
                alt=""
                class="noti"
            />
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> QuimiChis <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 8-------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-4 columna">
          <div>
<!--            <img src="../assets/imagenes/ig/museo_america.png" alt="Museo America" class="img-thumbnail img_rectangulares"/>-->
            <img src="../assets/imagenes/ig/museo_america.png" alt="Museo America" class="img_rectangulares"/>
            <p class="texto4 textogris">
              <b>Cliente:</b> Museo de América<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div>
<!--            <img src="../assets/imagenes/ig/restaurante_oriental.png" alt="" class="img-thumbnail img_cuadradas"/>-->
            <img src="../assets/imagenes/ig/restaurante_oriental.png" alt="" class="img_cuadradas"/>
            <p class="texto4 textogris">
              <b>Cliente:</b> Restaurante Oriental<br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
        <div class="col-4 columna">
          <div class="text-center ">
<!--            <img src="../assets/imagenes/ig/tu_vivienda.png" alt="Itsm" class="img-thumbnail img_rectangulares"/>-->
            <img src="../assets/imagenes/ig/tu_vivienda.png" alt="Itsm" class="img_rectangulares"/>
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> I.T.E.S.M. <br/>
              <b>Lugar:</b> Monterrey, México
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------Fila 9-------------->
    <div class="container-fluid contimg" style="margin-bottom: 10%;">
      <div class="row">
        <div class="col-6 columna">
          <div>
            <img src="../assets/imagenes/ig/la_rancherita.png" alt="" class="ranche"/>
          </div>
          <p class="texto4 textogris ">
            <b>Cliente:</b> Crema y queso de Huitzuco<br/>
            <b>Lugar:</b> Acapulco, México
          </p>
        </div>
        <div class="col-6 columna">
          <div>
            <img src="../assets/imagenes/ig/silvia_ruiz.png" alt="" class="img_rectangulares"/>
            <p class="texto4 textogris texto4-1">
              <b>Cliente:</b> Empresa de Repostería <br/>
              <b>Lugar:</b> Madrid, España
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagenGrafica",
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backazul']);
  }
}
</script>

<style scoped>

</style>